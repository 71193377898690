<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Choose Report
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field has-addons">
            <input class="is-checkradio"
              checked
              id="detailed"
              type="radio"
              name="reportType"
              value="detailed"
              v-model="reportType">
            <label for="detailed">Detailed Report</label>
            <input class="is-checkradio"
              id="summary"
              type="radio"
              name="reportType"
              value="summary"
              v-model="reportType">
            <label for="summary">Summary Report</label>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onOkClick">
            <span class="icon">
              <i class="mdi mdi-file-document mdi-18px" />
            </span>
            <span>Show</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'

export default {
  name: 'CreditorAgingReportSideMenu',
  components: {
    AppSideMenu
  },
  props: {},
  data() {
    return {
      reportType: 'detailed'
    }
  },
  computed: {},
  created() {},
  methods: {
    onOkClick() {
      this.$emit('ok', this.reportType)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
