<template>
  <div v-if="$userInfo">
    <print-preview-with-side-view
      :emailer="emailer"
      :report-name="reportName"
      :parameters="parameters"
      :parameter-id="parameterId">
      <template v-slot:side-bar>
        <creditor-aging-report-side-menu @ok="okButton" />
      </template>
    </print-preview-with-side-view>
  </div>
</template>

<script>
import { AppHeaderButtonTypes } from '@/enums'
import { Emailer } from '@/classes'
import { PrintPreviewWithSideView } from '@/components/printpreview'
import CreditorAgingReportSideMenu from './CreditorAgingReportSideMenu'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DebtorAgingReportView',
  components: {
    PrintPreviewWithSideView,
    [CreditorAgingReportSideMenu.name]: CreditorAgingReportSideMenu
  },
  data() {
    return {
      reportName: '',
      reportTitle: '',
      query: '',
      reportType: '',
      emailer: new Emailer(),
      parameters: new KeyValuePairModel(),
      parameterId: ''
    }
  },
  created() {
    this.initialReport()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    initialReport() {
      this.reportName = 'AT_APDetailedAgingReportV2'
      this.reportTitle = 'Accounts Payable Detailed Aging Report'
      this.emailer.subject = 'A/P Aging Detailed Report'
      this.emailer.reportName = 'APDetailedAgingReport'
      let params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: this.reportTitle,
        CompanyID: this.$userInfo.companyId
      }

      this.parameters = KeyValuePairModel.convertToKeyValuePairs(params)
      this.parameterId = this.$guid.newGuid()
    },
    okButton(reportType) {
      if (reportType === 'detailed') {
        this.reportName = 'AT_APDetailedAgingReportV2'
        this.reportTitle = 'Accounts Payable Detailed Aging Report'
        this.emailer.subject = 'A/P Aging Detailed Report'
        this.emailer.reportName = 'APDetailedAgingReport'
      } else if (reportType === 'summary') {
        this.reportName = 'AT_APSummaryAgingReportV2'
        this.reportTitle = 'Accounts Payable Summary Aging Report'
        this.emailer.subject = 'A/P Aging Summary Report'
        this.emailer.reportName = 'APSummaryAgingReport'
      }
      let params = {
        COMPANYNAME: this.$userInfo.companyName,
        REPORTTITLE: this.reportTitle,
        CompanyID: this.$userInfo.companyId
      }

      this.parameters = KeyValuePairModel.convertToKeyValuePairs(params)
      this.parameterId = this.$guid.newGuid()
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
